// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #f3df33; // #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

//$enable-gradients:                            false !default;
$border-radius:               0;//.25rem !default;


$blue: #5e72e4;
$cyan: #11cdef;
$green: #2FB933; // #2dce89;
$red: #f5365c;
$orange: #fb6340;
$gray-100: #f4f5f7;
$gray-200: #dee0e2;
$teal: #2dce89;
$warning: $yellow;
$secondary: $purple;
$yiq-contrasted-threshold: 150;
$gray-800: #172b4d;
$body-bg: $gray-200;
$border-width: 1px;
$border-radius: 2px;
$border-radius-lg: 2px;
$border-radius-sm: 2px;
$table-color: $gray-800;
$table-accent-bg: rgba($gray-800, .05);
$table-border-color: $gray-800;
$table-head-bg: $gray-800;
$table-head-color: $gray-800;
$table-striped-order: even;